import React, { useEffect } from "react";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SolutionsHeader from "../../molecules/solutions/SolutionsHeader";
import SolutionsServices from "../../molecules/solutions/SolutionsServices";
import MobileImgIn from "../../images/servicesassets/banners/ProdDevMobileIn.png";
import MobileImgEn from "../../images/servicesassets/banners/ProdDevMobileEn.png";
import MobileImgJa from "../../images/servicesassets/banners/ProdDevMobileJa.png";
import { useStaticQuery, graphql } from "gatsby";
import { useLanguage } from "../../languageContext";

const ProductDevelopment = () => {
  const currentLanguage = useLanguage();
  const data = useStaticQuery(graphql`
    query GetProductDevelopmentData {
      gcms {
        solutionsPages(where: { solutionsPageType: PRODUCT_DEVELOPMENT }) {
          heading
          paragraph
          solutionsPageSlides {
            paragraph
            slideHeading
            listItems
            url
            slideImage {
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <Layout isNavWhite={true} isNavTransparent={true} hideMap hideFAQ>
      <SEO
        canonicalLink={
          "https://www.goken-global.com/solutions/productdevelopment/"
        }
        title={"Collaborative Engineering Benefits "}
        description={`Accelerate your product development with Goken Global's innovative and comprehensive solutions.`}
        facebookTitle={
          "Goken | Best Product Engineering Services in America and India "
        }
        facebookDescription={
          "Goken is an expert in providing product engineering services which range from design, engineering, and validation services. Our added capabilities include electrical systems, embedded systems, and software application development. "
        }
        keywords={
          "product design engineering firm, product engineering services"
        }
        // meta="Product Development, Product, Development, new product development, product development process, product development lifecycle, product design"
      ></SEO>
      {data.gcms && (
        <>
          <SolutionsHeader
            headingOne="Product Development"
            headingTwo=""
            paragraph={data.gcms.solutionsPages[0].paragraph}
            btnText="Talk To An Expert"
            page="productdev"
            mobileImage={
              currentLanguage === "en"
                ? MobileImgEn
                : currentLanguage === "in"
                ? MobileImgIn
                : currentLanguage === "ja"
                ? MobileImgJa
                : MobileImgEn
            }
            mobileAltText={"Product Development Mobile Graphic"}
          ></SolutionsHeader>
          <SolutionsServices
            headingOne="Our services range"
            headingTwo="from concept to"
            headingThree="mass production"
            SliderItems={data.gcms.solutionsPages[0].solutionsPageSlides}
          ></SolutionsServices>
        </>
      )}
      <ImageBanner
        heading="Interested to learn more about Product Development?"
        link="/contactus/"
        btnText="Get in touch"
      ></ImageBanner>
    </Layout>
  );
};

export default ProductDevelopment;
